import { ref, reactive } from "vue";
import c3api from "../c3api";
import { useRouter } from "vue-router/auto";
import { useToast } from "vue-toastification";
import { unwrapReactive } from "../utils/unwrapReactive";
import { useLoadingStore } from "../stores/loading";

export default function useOutboundOrders() {
	const outboundOrders = ref(null);
	const outboundOrder = ref(null);
	const outboundLoadingCount = ref(0);
	const lineItems = ref(null);
	const orderDocuments = ref(null);
	const toast = useToast();
	const defaultLocalState = reactive({});
	const router = useRouter();
	const loading = useLoadingStore();
	const uploaderInfo = ref(null); // New reactive variable for uploader info

	const fetchMissing945s = async (localState = defaultLocalState) => {
		loading.state(true)
		try {
			const params = unwrapReactive(localState);
			const response = await c3api.get("/outbound_orders/missing_945_edi_request", { params });
			loading.state(false)
			return outboundOrders.value = response.data;
		} catch (error) {
			toast.error(error.response.data.data.join(". "));
		} finally {
			loading.state(false)
		}
	}

	const postOutboundEDIRequest = async (id) => {
		loading.state(true)
		try {
			const response = await c3api.post(`/outbound_orders/${id}/edi_request`);
			await fetchMissing945s();
			loading.state(false)
			return response.data;
		} catch (error) {
			toast.error(error.response.data.data.join(". "));
		} finally {
			loading.state(false)
		}
	}

	function updatePage(newPage) {
		router.push({ query: { page: newPage } });
	}

	const fetchOutboundOrder = async (id, localState = defaultLocalState) => {
		outboundLoadingCount.value++;
		try {
			const params = unwrapReactive(localState);
			const response = await c3api.get(`/outbound_orders/${id}`, { params });
			return (outboundOrder.value = response.data.data);
		} catch (error) {
			toast.error(error.response.data.data.join(". "));
		} finally {
			outboundLoadingCount.value--;
		}
	};

	const fetchOutboundOrderLineItems = async (
		id,
		localState = defaultLocalState
	) => {
		outboundLoadingCount.value++;
		try {
			const params = unwrapReactive(localState);
			const response = await c3api.get(`/outbound_orders/${id}/line_items`, {
				params,
			});

			return (lineItems.value = response.data);
		} catch (error) {
			toast.error(error.response.data.data.join(". "));
		} finally {
			outboundLoadingCount.value--;
		}
	};

	const fetchOutboundOrderDocuments = async (id) => {
		try {
			const params = unwrapReactive(localState);
			const response = await c3api.get(
				`/outbound_orders/${id}/order_documents`,
				{ params }
			);
			return (orderDocuments.value = response.data);
		} catch (error) {
			toast.error(error.response.data.data.join(". "));
		} finally {
			outboundLoadingCount.value--;
		}
	};
	const fetchOutboundOrderAppointments = async (id) => {
		outboundLoadingCount.value++;
		try {
			const response = await c3api.get(`/outbound_orders/${id}/appt`);
			return response.data.data;
      	} catch (error) {
			toast.error(error.response.data.data.join(". "));
		} finally {
			outboundLoadingCount.value--;
		}
	};

	const fetchOutboundOrderShipments = async (
		id,
		localState = defaultLocalState
	) => {
		outboundLoadingCount.value++;
		try {
			const params = unwrapReactive(localState);
			const response = await c3api.get(`/outbound_orders/${id}/shipments`, {
				params,
			});
			return response.data;
		} catch (error) {
			toast.error(error.response.data.data.join(". "));
		} finally {
			outboundLoadingCount.value--;
		}
	};

	const printDocs = async (apiUrl) => {
    console.log(apiUrl);
    try {
      outboundLoadingCount.value++;
      const response = await c3api.get(apiUrl, { responseType: 'blob' });
      console.log(response, 'res');
      const url = URL.createObjectURL(response.data);
      window.open(url, '_blank');
    } catch (error) {
      toast.error(error.response.data.data.join(". "));
    } finally {
      outboundLoadingCount.value--;
    }
  };

	const fetchUploaderInfo = async () => {
		try {
			outboundLoadingCount.value++;
			const response = await c3api.get("/outbound_orders/uploader");
			uploaderInfo.value = response.data.data; // Store the response data
		} catch (error) {
			toast.error(error.response?.data?.data.join(". "));
		} finally {
			outboundLoadingCount.value--;
		}
	};

	return {
		outboundOrders,
		outboundOrder,
		outboundLoadingCount,
		lineItems,
		orderDocuments,
		fetchMissing945s,
		postOutboundEDIRequest,
		updatePage,
		fetchOutboundOrder,
		fetchOutboundOrderLineItems,
		fetchOutboundOrderDocuments,
		fetchOutboundOrderAppointments,
		fetchOutboundOrderShipments,
		printDocs,
		fetchUploaderInfo, // Add the new function to the return object
		uploaderInfo, // Add the new reactive variable to the return object
	};
}
